import React, {useState} from 'react';
import {Col, Container, Form, Navbar, Row} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxTKHooks';
import {loginAsync} from '../../redux/signinSlice';
import {generateBasicToken} from '../../utils';
import _ from 'lodash';
import {useTranslation} from '../../hooks/useTranslation';
import logo from '../../assets/ionnyk_logo_black.png';
import {Footer} from '../../components/Footer';

interface Props {
  toggleAuth?: () => void;
  toggleForgotPsw?: () => void;
}

export const Login = ({toggleAuth, toggleForgotPsw}: Props) => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const authState = useAppSelector((state) => state.auth);
  // FIXME basic form
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const generatedToken = generateBasicToken(email, password);
  const displayServerError =
    !!authState.serverError &&
    _.isEqual(generatedToken, authState.serverError.submittedData);

  return (
    <Container style={{padding: '25px'}}>
      <Row style={{marginBottom: '5rem'}}>
        <Col>
          <img
            style={{
              // image centering :
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src={logo}
            height="30rem"
            // className="d-inline-block align-center"
            alt="Ionnyk logo"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{span: 6, offset: 3}}>
          <h3>Login</h3>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t('email_label')}</Form.Label>
              <Form.Control
                type="email"
                autoFocus
                autoComplete="username email"
                placeholder={t('email_pl')}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>{t('psw_label')}</Form.Label>
              <Form.Control
                type="password"
                autoComplete="current-password"
                placeholder={t('psw_pl')}
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </Form.Group>

            <div>
              <Button
                style={{width: '100%'}}
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault(); // stop submit form
                  e.stopPropagation();
                  dispatch(loginAsync(generatedToken));
                }}>
                {t('signin_btn')}
              </Button>
              {displayServerError && (
                <Form.Text style={{color: 'red'}}>
                  {t('invalid_login')}
                </Form.Text>
              )}
              <hr style={{color: 'black', width: '100%'}} />
              <Button
                style={{width: '100%'}}
                variant="light"
                onClick={() => toggleAuth && toggleAuth()}>
                {t('signup_btn')}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      <br />
      <Row>
        <Col
          md={{span: 6, offset: 3}}
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <a
            className="text-muted"
            style={{
              alignSelf: 'center',
              textDecoration: 'underline',
              fontStyle: 'italic',
              textAlign: 'center',
            }}
            href={toggleForgotPsw ? '#' : '/signup'}
            onClick={toggleForgotPsw && toggleForgotPsw}>
            {t('forgotpsw_btn')}
          </a>
          <a
            className="text-muted"
            style={{
              alignSelf: 'center',
              textDecoration: 'underline',
              fontStyle: 'italic',
              textAlign: 'right',
            }}
            href={'https://support.ionnyk.com'}>
            {t('contact_us')}
          </a>
        </Col>
      </Row>
      <Row className="mt-3">
        <Footer />
      </Row>
    </Container>
  );
};
