import axios, {AxiosInstance as TAxiosInstance} from 'axios';
export const __DEV__ =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const DEFAULT_API_REQUEST_TIMEOUT = 5000;
// const ROOT_URL = process.env.REACT_APP_API_BASE_URL;
// const BASE_URL = ROOT_URL + '/client/v1/';
// const BASE_URL_ANON = ROOT_URL + '/anon/v1/';

// FIXME cleaner staging vs prod config - use ENV variables
// FIXME more secured than clear text
export const localConfig = {
  // PRODUCTION
  REACT_APP_PROFILE_FLAG: '1',
  REACT_APP_ONBOARDING_FLAG: '0',
  SITE_KEY: '6Lcf7MgaAAAAANwsV0EEfjIjOQEKaHSNvfiwXJn3', // Captcha - FIXME env
  STRIPE_KEY:
    'pk_live_51IQYoHIcbdUWJa9isBXQLHcEYgQuwLRafctUCZqUMDyYFnE5aiQOgjYTf6k3nDfSLQEsEo5lL7n2k32EAVtvaHR000FUBNz2dM', // FIXME env
};
export const apiUrl = (() => {
  switch (window.location.origin) {
    case 'https://my.ionnyk.com':
      localConfig.STRIPE_KEY =
        'pk_live_51IQYoHIcbdUWJa9isBXQLHcEYgQuwLRafctUCZqUMDyYFnE5aiQOgjYTf6k3nDfSLQEsEo5lL7n2k32EAVtvaHR000FUBNz2dM'; // FIXME env
      return process.env.REACT_APP_API_BASE_URL_PROD;
    case 'http://localhost:3000':
    case 'http://127.0.0.1:3000':
    // DEV
    // localConfig.REACT_APP_PROFILE_FLAG = '1';
    // localConfig.REACT_APP_ONBOARDING_FLAG = '0';
    // localConfig.SITE_KEY = '6Ldthb0aAAAAAPlJo0T8tVUc0wzNwonBTWNyu7Um'; // FIXME env
    // localConfig.STRIPE_KEY =
    //   'pk_test_51KlskdDOrVY2QvfKLsEFTQhmbMulQQlQRRfLEeouQu64R9nMrH4kCXlC1Hms3oPtMomI1nbTRHLp5WoUEFKQYxP800AHbsot48'; // FIXME env
    // return 'http://localhost:8080';
    // return 'https://a690-109-135-26-232.eu.ngrok.io';
    case 'https://portal.ionnyk-dev.inkcoming.eu':
      // DEV FIXME
      localConfig.REACT_APP_PROFILE_FLAG = '1';
      localConfig.REACT_APP_ONBOARDING_FLAG = '0'; // FIXME to get back
      localConfig.SITE_KEY = '6Ldthb0aAAAAAPlJo0T8tVUc0wzNwonBTWNyu7Um'; // FIXME env DEV
      localConfig.STRIPE_KEY =
        'pk_test_51IQYoHIcbdUWJa9iYwPRM9VLM3OH4eSQFYy1p3BAnLRwVGRQxubcKQkiVsWUihHs6uanJDvhY2PbykhPkiy9eCoI00VUpN0nOo'; // FIXME env DEV
      return 'https://backend.ionnyk-dev.inkcoming.eu';
    case 'https://admin.akom-dev-1.inkcoming.eu':
    case 'https://ionnyk-portal-staging.inkcoming.eu': // @deprecated
    case 'https://portal.ionnyk-staging.inkcoming.eu':
    default:
      // STAGING
      localConfig.REACT_APP_PROFILE_FLAG = '1';
      localConfig.REACT_APP_ONBOARDING_FLAG = '0'; // like prod
      localConfig.SITE_KEY = '6Ldthb0aAAAAAPlJo0T8tVUc0wzNwonBTWNyu7Um'; // FIXME env
      localConfig.STRIPE_KEY =
        'pk_test_51IQYoHIcbdUWJa9iYwPRM9VLM3OH4eSQFYy1p3BAnLRwVGRQxubcKQkiVsWUihHs6uanJDvhY2PbykhPkiy9eCoI00VUpN0nOo'; // FIXME env
      return 'https://api-staging.inkcoming.eu';
  }
})();
const BASE_URL = apiUrl + '/client/v1/';
const BASE_URL_ANON = apiUrl + '/anon/v1/';

// redux helpers
export interface RequestStatus {
  loading: boolean;
  serverError?: APIErrorClient;
}
export const initialRequestStatus: RequestStatus = {
  loading: false,
  serverError: undefined,
};

// http errors
export const SERVER_ERROR_STATUS = 500;
export const CUSTOM_CONFLICT_STATUS = 409;
export const CLIENT_BAD_REQUEST_STATUS = 400;
export const CLIENT_BAD_REQUEST_STATUS_STRING = 'CLIENT_BAD_REQUEST';
export const UNAUTHORIZED_STATUS = 401;
export const NOT_FOUND_STATUS = 404;
export const FORBIDDEN_STATUS = 403;

export const UNKNOWN_ERROR_KEY = 'UNKNOWN_ERROR_KEY';
export interface APIErrorClient {
  errorKey: string;
  submittedData: any;
  statusCode?: any;
}
// FIXME request id
export const handleNetworkError = (
  src: string,
  e: any,
  submittedData: any = {},
  additionalClientErrors: number[] = [], // any error status we want to display in custom fashion
  silent: boolean = false,
) => {
  console.warn('Network error for ' + src, {
    // ...e,
    // client config
    method: e.config?.method,
    fullURL: e.config && `${e.config.baseURL}${e.config.url}`,
    params: e.config?.params,
    body: e.config?.body,
    // http layer
    status: e.response?.status,
    statusText: e.response?.statusText,
    // Server specific
    error: e.response?.data?.error,
    code: e.response?.data?.code,
    description: e.response?.data?.description,
    // axios error layer
    axiosError: e.name,
    axiosErrorMsg: e.message,
  });

  if (!!e?.axiosErrorMsg?.startsWith('timeout')) {
    console.warn('Timeout encountered');
    return {
      errorKey: UNKNOWN_ERROR_KEY,
      statusCode: SERVER_ERROR_STATUS, // FIXME better ?
      submittedData,
    } as APIErrorClient;
  }

  if (e?.response?.status === CUSTOM_CONFLICT_STATUS && e?.response?.data) {
    // Known Server Error to handle from client
    return {
      errorKey: e.response.data.code,
      statusCode: e.response.status,
      submittedData,
    } as APIErrorClient;
  } else if (e?.response?.status === CLIENT_BAD_REQUEST_STATUS) {
    // General Server Error to handle from client
    return {
      errorKey: CLIENT_BAD_REQUEST_STATUS_STRING,
      statusCode: e?.response?.status,
      submittedData,
    } as APIErrorClient;
  } else if (additionalClientErrors?.includes(e?.response?.status)) {
    return {
      errorKey: e?.response?.data?.error || e?.error,
      statusCode: e?.response?.status,
      submittedData,
    } as APIErrorClient;
  } else {
    // Unknown Server Error
    !silent && alert(e?.message); // undefined is returned
  }
};

const axiosBaseConfig = {
  timeout: DEFAULT_API_REQUEST_TIMEOUT,
  headers: {
    'ngrok-skip-browser-warning': true,
    Accept: 'application/json',
  },
};

export const anonApi: TAxiosInstance = axios.create({
  ...axiosBaseConfig,
  baseURL: BASE_URL_ANON,
});

// This will be setup after login. Before login, the default Authorization will be undefined
// and backend server will reject any request with HTTP code 401
// authorizedAxiosInstance.interceptors.request.use(
//   config => {
//     config.headers.Authorization = 'Basic dXNlckBpbmtjb21pbmcuY29tOnBhc3N3b3Jk';
//     return config;
//   },
//   err => Promise.reject(err),
// );

export const authApi: TAxiosInstance = axios.create({
  ...axiosBaseConfig,
  baseURL: BASE_URL,
});

// AUTH
let interceptorID: number | null = null;
export function installBasicAuthHeader(token: string) {
  // NB: will override any local config set for Authorization
  if (interceptorID != null) {
    authApi.interceptors.request.eject(interceptorID);
  }
  interceptorID = authApi.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Basic ${token}`;
      // api = new Api({...config, baseURL: apiUrl}); // new API wrapper auto-gen from swagger !
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}
